<!--
程序版本：V2.0
版权所有：深圳市科飞时速网络科技有限公司
技术支持：Tech@21gmail.com
单元名称：客户·添加新客户·信息匹配弹窗
开始时间：2017-11-9
开发人员：陈立珍
最后修改：2018-9-27
备注说明：如需修改请联系开发人员
-->

<template>
  <div id="crm-connectInfo" >
    <div class="connectInfo">
      <el-dialog
        title="信息匹配"
        class='yourName layer-systemImp dialogTwo'
        size="tiny"
        :visible.sync="test"
        :before-close="cntClose"
        :modal="false"
        :modal-append-to-body="true"
        :lock-scroll="true"
        :close-on-click-modal="false">
        <popupLoading></popupLoading>
        <div class="div_table">
          <table id="before_import_result" width="100%" cellpadding="0" cellspacing="12">
            <tr>
              <td style="font-weight: bold;font-size: 16px;">您的文档内容</td>
              <td>匹配到</td>
              <td style="font-weight: bold;font-size: 16px;">易外销系统</td>
            </tr>
            <tr v-for="tr in tr_list">
              <td><span>{{tr.name}}</span></td>
              <td>--&gt;</td>
              <td>
                <select class="crm-select" name="import_select">
                	<option v-for="select in select_list" :value="select.id">{{select.name}}</option>
                </select>
              </td>
            </tr>
          </table>
        </div>
        <span slot="footer" class="dialog-footer">
        	<el-button @click="cntConfirm" type="primary">确 定</el-button>
          <el-button  @click="cntClose" style="margin-bottom:10px;">取 消</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
	import generImportSelect from './createClient_ordinaryImport_select.vue'
  import store from '@/vuex/store'
  import {mapState, mapGetters, mapMutations, mapActions} from 'vuex'
  import popupLoading from '@/components/publicComponent/popupLoading'
  export default{
    name:"cm-more-info",
    data(){
      return{
        test:true,
        value1:null,
        tr_list:[
        	
        ],
        select_list:[
        	{"id":-1,"name":"请选择"},
        	{"id":0,"name":"客户全称*"},
        	{"id":21,"name":"客户编号"},
        	{"id":1,"name":"联系人姓名"},
        	{"id":2,"name":"所在国家"},
        	{"id":3,"name":"联系人邮箱*"},
        	{"id":4,"name":"联系人电话"},
        	{"id":5,"name":"省份/州/区"},
        	{"id":6,"name":"城市"},
        	{"id":7,"name":"企业简称"},
        	{"id":8,"name":"手机"},
        	{"id":9,"name":"职位"},
        	{"id":10,"name":"传真"},
        	{"id":11,"name":"网址"},
        	{"id":12,"name":"地址"},
        	{"id":13,"name":"facebook"},
        	{"id":14,"name":"twitter"},
        	{"id":15,"name":"linkedin"},
        	{"id":16,"name":"pinterest"},
        	{"id":17,"name":"google"},
        	{"id":18,"name":"tumblr"},
        	{"id":19,"name":"instagram"},
        	{"id":20,"name":"vk"},
        ],
      }
    },
    props:[
      'cntTitle',
      'hasButton',
      'import_classifyid',
      'import_sourceid',
      'import_partnerid',
      'import_statusid',
      'import_hangyeid',
      'import_productid',
      'excelTableHeaders',
      'importFileName',
      'importFilePath',
      'import_type',
    ],
    components: {
		popupLoading
	},
    computed: {
      ...mapState({
        companyparamlist: state => state.companyparamlist,
      }),
    },
    methods:{
    	//触发父组件关闭弹窗
      cntClose:function(){
        this.$emit('cntClose',false)
      },
      cntConfirm:function(){
        //this.$emit('cntConfirm')
        var cusname_num = 0;//选择客户名称次数
				var linkman_num = 0;//选择联系人姓名次数
				var country_num = 0;//选择所在国家次数
				var email_num = 0;//选择联系人邮箱次数
				var phone_num = 0;//选择联系人电话次数
				var province_num = 0;//选择省份/州/区次数
				var city_num = 0;//选择城市次数
				var scusname_num = 0;//选择企业简称次数
				var mobile_num = 0;//选择手机次数
				var post_num = 0;//选择职位次数
				var fax_num = 0;//选择传真次数
				var website_num = 0;//选择网址次数
				var address_num = 0;//选择地址次数
				var facebook_num = 0;//选择facebook次数
				var twitter_num = 0;//选择twitter次数
				var linkedin_num = 0;//选择linkedin次数
				var pinterest_num = 0;//选择pinterest次数
				var google_num = 0;//选择google次数
				var tumblr_num = 0;//选择tumblr次数
				var instagram_num = 0;//选择instagram次数
				var vk_num = 0;//选择vk次数
				var code_num = 0;//选择客户编号次数
				var options = "";
				
				$("select[name='import_select']").each(function () {
					var value = parseInt($(this).val());
					options += value + "###";
					switch (value) 
					{
						case 0:
							cusname_num++;
							break;
						case 1:
							linkman_num++;
							break;
						case 2:
							country_num++;
							break;
						case 3:
							email_num++;
							break;
						case 4:
							phone_num++;
							break;
						case 5:
							province_num++;
							break;
						case 6:
							city_num++;
							break;
						case 7:
							scusname_num++;
							break;
						case 8:
							mobile_num++;
							break;
						case 9:
							post_num++;
							break;
						case 10:
							fax_num++;
							break;
						case 11:
							website_num++;
							break;
						case 12:
							address_num++;
							break;
						case 13:
							facebook_num++;
							break;
						case 14:
							twitter_num++;	
							break;
						case 15:
							linkedin_num++;
							break;
						case 16:
							pinterest_num++;
							break;
						case 17:
							google_num++;
							break;
						case 18:
							tumblr_num++;
							break;
						case 19:
							instagram_num++;
							break;
						case 20:
							vk_num++;
							break;
						case 21:
							code_num++;
							break;
						default:
							break;
					}
				});
				
				//去掉最后个###
				options = options.substring(0,options.length-3);
				
				if(cusname_num == 0)
				{
					this.$message({
	          type: 'warning',
	          showClose: true,
	          message: '客户全称和联系人邮箱是必选项，请匹配客户全称!',
	          duration:2000
	        });
					return;
				}
				
				if(email_num == 0)
				{
					this.$message({
	          type: 'warning',
	          showClose: true,
	          message: '客户全称和联系人邮箱是必选项，请匹配联系人邮箱!',
	          duration:2000
	        });
					return;
				}
				
				if(cusname_num > 1)
				{
					this.$message({
	          type: 'warning',
	          showClose: true,
	          message: '客户全称只能匹配一次，您已经匹配了多次!',
	          duration:2000
	        });
					return;
				}
				
				if(linkman_num > 1)
				{
					this.$message({
	          type: 'warning',
	          showClose: true,
	          message: '联系人姓名只能匹配一次，您已经匹配了多次!',
	          duration:2000
	        });
					return;
				}
				
				if(country_num > 1)
				{
					this.$message({
	          type: 'warning',
	          showClose: true,
	          message: '所在国家只能匹配一次，您已经匹配了多次!',
	          duration:2000
	        });
					return;
				}
				
				if(email_num > 1)
				{
					this.$message({
	          type: 'warning',
	          showClose: true,
	          message: '联系人邮箱只能匹配一次，您已经匹配了多次!',
	          duration:2000
	        });
					return;
				}
				
				if(phone_num > 1)
				{
					this.$message({
	          type: 'warning',
	          showClose: true,
	          message: '联系人电话只能匹配一次，您已经匹配了多次!',
	          duration:2000
	        });
					return;
				}
				
				if(province_num > 1)
				{
					this.$message({
	          type: 'warning',
	          showClose: true,
	          message: '省份/州/区只能匹配一次，您已经匹配了多次!',
	          duration:2000
	        });
					return;
				}
				
				if(city_num > 1)
				{
					this.$message({
	          type: 'warning',
	          showClose: true,
	          message: '城市只能匹配一次，您已经匹配了多次!',
	          duration:2000
	        });
					return;
				}
				
				if(scusname_num > 1)
				{
					this.$message({
	          type: 'warning',
	          showClose: true,
	          message: '企业简称只能匹配一次，您已经匹配了多次!',
	          duration:2000
	        });
					return;
				}
				
				if(mobile_num > 1)
				{
					this.$message({
	          type: 'warning',
	          showClose: true,
	          message: '手机只能匹配一次，您已经匹配了多次!',
	          duration:2000
	        });
					return;
				}
				
				if(post_num > 1)
				{
					this.$message({
	          type: 'warning',
	          showClose: true,
	          message: '职位只能匹配一次，您已经匹配了多次!',
	          duration:2000
	        });
					return;
				}
				
				if(fax_num > 1)
				{
					this.$message({
	          type: 'warning',
	          showClose: true,
	          message: '传真只能匹配一次，您已经匹配了多次!',
	          duration:2000
	        });
					return;
				}
				
				if(website_num > 1)
				{
					this.$message({
	          type: 'warning',
	          showClose: true,
	          message: '网址只能匹配一次，您已经匹配了多次!',
	          duration:2000
	        });
					return;
				}
				
				if(address_num > 1)
				{
					this.$message({
	          type: 'warning',
	          showClose: true,
	          message: '地址只能匹配一次，您已经匹配了多次!',
	          duration:2000
	        });
					return;
				}
				
				if(facebook_num > 1)
				{
					this.$message({
	          type: 'warning',
	          showClose: true,
	          message: 'facebook只能匹配一次，您已经匹配了多次!',
	          duration:2000
	        });
					return;
				}
				
				if(twitter_num > 1)
				{
					this.$message({
	          type: 'warning',
	          showClose: true,
	          message: 'twitter只能匹配一次，您已经匹配了多次!',
	          duration:2000
	        });
					return;
				}
				
				if(linkedin_num > 1)
				{
					this.$message({
	          type: 'warning',
	          showClose: true,
	          message: 'linkedin只能匹配一次，您已经匹配了多次!',
	          duration:2000
	        });
					return;
				}
				
				if(pinterest_num > 1)
				{
					this.$message({
	          type: 'warning',
	          showClose: true,
	          message: 'pinterest只能匹配一次，您已经匹配了多次!',
	          duration:2000
	        });
					return;
				}
				
				if(google_num > 1)
				{
					this.$message({
	          type: 'warning',
	          showClose: true,
	          message: 'google只能匹配一次，您已经匹配了多次!',
	          duration:2000
	        });
					return;
				}
				
				if(tumblr_num > 1)
				{
					this.$message({
	          type: 'warning',
	          showClose: true,
	          message: 'tumblr只能匹配一次，您已经匹配了多次!',
	          duration:2000
	        });
					return;
				}
				
				if(instagram_num > 1)
				{
					this.$message({
	          type: 'warning',
	          showClose: true,
	          message: 'instagram只能匹配一次，您已经匹配了多次!',
	          duration:2000
	        });
					return;
				}
				
				if(vk_num > 1)
				{
					this.$message({
	          type: 'warning',
	          showClose: true,
	          message: 'vk只能匹配一次，您已经匹配了多次!',
	          duration:2000
	        });
					return;
				}
				
				if(code_num > 1)
				{
					this.$message({
	          type: 'warning',
	          showClose: true,
	          message: '客户编号只能匹配一次，您已经匹配了多次!',
	          duration:2000
	        });
					return;
				}
				var import_productid = '';
				if(this.import_productid.length > 0){
					for(var i = 0;i < this.import_productid.length;i++){
						import_productid += this.import_productid[i] + '#';
					}
					import_productid = import_productid.substring(0,import_productid.length-1);
				}else{
					import_productid = '0';
				}
				//行业id//产品id//分类id//状态id//关系id//来源id
				var import_customer_type = this.import_hangyeid+ "," + import_productid+ "," + this.import_classifyid + "," + this.import_statusid + "," + this.import_partnerid + "," + this.import_sourceid;
				var lef = this;
				$.ajax({
					"type": "POST",
					"async": true,
					"cache": false,
					"url": backUrl + "cus_addImportCustomerByExcelBefore.action",
					"data": {
						token: token,
						customerFileName:lef.importFileName,//文件名
						filepath:lef.importFilePath,//临时文件路径
						options:options,//匹配字段
						classify_id:lef.import_classifyid,//客户分类id
						customer_type:import_customer_type,
						import_type: lef.import_type,//公海还是私海
						admin_can_repeat:lef.companyparamlist.param159//系统后台创建客户是否重复,0:不允许  1:允许
					},
					beforeSend: function() {
					lef.$store.state.popupLoading = true;
				},
				complete: function() {
					lef.$store.state.popupLoading = false;
				},
					success: function (msg) 
					{
						if (msg == 1) 
						{
							lef.$emit('cntClose',false)
							lef.$message({
	              type: 'success',
	              showClose: true,
	              message: '文件上传成功，后台正在努力处理中，请耐心等待，稍后会有信息提醒您！',
	              duration:2000
	            });
	            lef.$emit('subRefresh');
						} 
						else 
						{
							lef.$message({
	              type: 'warning',
	              showClose: true,
	              message: '出错了，请联系客服进行协助！',
	              duration:2000
	            });
						}
					}, 
					error: function () {
						lef.$message({
	            type: 'warning',
	            showClose: true,
	            message: '出错了，请联系客服进行协助！',
	            duration:2000
	          });
					}
				});
      }
    },
    mounted(){
    	var lef = this;
    	$.each(this.excelTableHeaders, function(index,row) {
    		if(row.trim().length > 0){
    			lef.tr_list.push({"name":row});
    		}
    	});
      function clearTransform(){
        $('.el-dialog').css({
          'transform': 'none',
        })
      };
      dialogDrag();
      clearTransform(dialogCenter());
    }
  }
</script>

<style lang="less">
  .connectInfo {
    .layer-systemImp{
      .el-dialog{
        width:450px;
        
       }
       .el-dialog__body{
       	padding: 10px 0px !important;
       }
    }
    .div_table{
      text-align: center;
      height: 330px;
    }
    .crm-select{
    	width: 140px;
    }
    .dialog-footer{
      margin-bottom:0;
    }
  }
</style>