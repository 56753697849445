<!--
程序版本：V2.0
版权所有：深圳市科飞时速网络科技有限公司
技术支持：Tech@21gmail.com
单元名称：客户·添加新客户·系统导入弹窗
开始时间：2017-11-9
开发人员：陈立珍
最后修改：2018-11-12
备注说明：如需修改请联系开发人员
-->

<template>
  <div id="systemImport" class="systemImport">
    <div>
      <form id="cus_import_from_system" method="post">
        <table width="100%" border="0" cellspacing="0" cellpadding="0">
          <tbody>
            <tr>
              <td colspan="4" class="crm-oItab-title">
                <b>
                  <span>系统导入</span>
                </b>
                <span class="gray">(系统导入不受日限制)</span>
              </td>
            </tr>
            <tr>
              <td height="8"></td>
            </tr>
            <tr>
              <td width="100" class="crm-oItab-left">
                <span>选择文件</span>
              </td>
              <td colspan="3" class="crm-oItab-right">
                <input
                  type="file"
                  id="system_import"
                  class="uploadBtn"
                  name="customerfile"
                  value="浏览"
                  accept=".xls, .xlsx, .csv"
                />
              </td>
            </tr>
            <tr>
              <td width="115" class="crm-oItab-left">字段匹配</td>
              <td colspan="3" class="crm-oItab-right">
                <a href="javascript:void(0);" class="example">
                  <span title="点击下载系统导入样例">
                    <a :href="generImportUrl1">下载</a>
                  </span>
                </a>
              </td>
            </tr>
             <tr>
              <td width="115" class="crm-oItab-left">模版下载</td>
              <td colspan="3" class="crm-oItab-right">
      						 <a href="javascript:void(0);" class="example">
      						 	<span title="点击下载系统导入样例"><a :href="generImportUrl">样例</a></span></a>
                <span class="gray">(文件表头的命名必须按照以下表格所述,位置可以不固定,支持xls,xlsx,csv)</span>
              </td>
            </tr>
            <tr>
              <td width="115" class="crm-oItab-left">表头说明</td>
              <td colspan="3" class="crm-oItab-right">
								<!-- <span @click="lookExplainJs">
									 <i class="el-icon-arrow-down curpot" v-if="!lookExplain" ></i>
                <i class="el-icon-arrow-up curpot curUp" v-else ></i>
								</span> -->
                <!-- <p style="margin-bottom:8px;">
                  
                  </p> -->
                  <p style="line-height:1.3; color:red">上传文件的表头命名规范如下，带 * 的为必填项</p>
                  <p style="line-height:1.3; color:red">客户分类、客户状态、客户来源、客户关系，请使用名称进行匹配</p>
                  <p style="line-height:1.3; color:red">关联产品，请使用产品编号进行匹配，可多个关联以英文逗号分割</p>
              </td>
            </tr>
            <tr >
              <td width="115" class="crm-oItab-left"></td>
              <td class="crm-oItab-right">
                
                <el-table :data="commonJs.downloadInfo()" style="width: 80%" class="cenTab">
                  <el-table-column prop="name" label="文件表头" width="180"></el-table-column>
                  <el-table-column prop="icon" label="匹配到" width="120" class="textTab"></el-table-column>
                  <el-table-column prop="msg" label="系统字段"></el-table-column>
                </el-table>
              </td>
            </tr>
            <!--<tr>
            <td width="100" class="crm-oItab-left">
              <span>系统来源</span>
            </td>
            <td colspan="3" class="crm-oItab-right">-->
            <!--<input type="radio" class="crm-input-radio" @click="clickShow"/>富通-->
            <!--  <el-radio class="radio" name="other_system" v-model="radio" label="1" v-bind="showInfos=radio?true:false">富通</el-radio>-->
            <!--<el-radio class="radio" v-model="radio" :label="true" @change="clickShow">富通</el-radio>-->
            <!--</td>
            </tr>-->
            <!--<tr v-show="showInfos">
            <td class="crm-oItab-left">
              <span>匹配信息</span>
            </td>
            <td colspan="3" class="crm-oItab-right">
              <select id="futong_tag_select">
                <option value="客户分类">客户分类</option>
                <option value="客户类型">客户状态</option>
              </select>
              <i class="icon iconfont icon-help-copy" @click="helpTip" title="匹配信息说明"></i>
            </td>
            </tr>-->
            <!--<tr>
            <td width="115" class="crm-oItab-left">模版下载</td>
            <td colspan="3" class="crm-oItab-right">
            	<el-tooltip class="item" effect="dark" :open-delay="300" content="点击下载富通系统样例" placement="top-start">
      					 <a :href="sysImportUrl" class="crm-system-example">富通</a>
    					</el-tooltip>
            </td>
            </tr>-->
          </tbody>
        </table>
      </form>
    </div>
    <layerNormal
      class="normal-tips"
      :cnt-title="title"
      @cntClose="showNormal = false"
      v-if="showNormal"
    ></layerNormal>
    <!--<div>
      <table width="100%" cellspacing="0" cellpadding="0" border="0">
        <tbody>
        <tr>
          <td colspan="4" class="crm-oItab-title">
            <b><span>注意事项</span></b>
          </td>
        </tr>
        <tr>
          <td height="8"></td>
        </tr>
        <tr class="myBussinessInfo">
          <td width="115" class="crm-oItab-left">
            <span>富通系统</span>
          </td>
          <td class="crm-oItab-right">
            <span class="tip-btn" @click="showNormal = true">
            		<el-tooltip class="item" effect="dark" :open-delay="300" content="点击查看注意事项" placement="top-start">
      					 <span>查看</span>
    						</el-tooltip>
            	</span>
          </td>
        </tr>
        </tbody>
      </table>
    </div>-->
  </div>
</template>
<script>
import layerNormal from "./createClient_systemImp_layer_normalTip.vue";
import store from "@/vuex/store";
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
export default {
  name: "systemImport",
  components: {
    layerNormal
  },
  data() {
    return {
      radio: false,
      showInfos: false,
      showNormal: false,
      sysImportUrl: "",
      title: "富通系统导入说明事项",
      generImportUrl1: "", //字段样例下载路径
       generImportUrl: "", //模板下载路径
      lookExplain: false, //是否展示表头说明,
      
    };
  },
  methods: {
    sysImport() {
      //判断是否选择文件
      var selectFile = $("#system_import").val();
      if (selectFile == "" || selectFile == null || selectFile == undefined) {
        this.$message({
          type: "warning",
          showClose: true,
          message: "请选择要上传的文件!",
          duration: 2000
        });
        return;
      }

      //判断格式是否正确
      var selectFileDivision = selectFile.split(".");
      var fileFormat = "." + selectFileDivision[selectFileDivision.length - 1];
      if (
        fileFormat != ".xls" &&
        fileFormat != ".xlsx" &&
        fileFormat != ".csv"
      ) {
        this.$message({
          type: "warning",
          showClose: true,
          message: "上传文格式不正确!",
          duration: 2000
        });
        return;
      }
      //判断上传文件的大小不能超过10M
      var fileInput = document.getElementById("system_import").files;
      if (fileInput != undefined) {
        if (fileInput[0].size > 1024 * 1024 * 10) {
          this.$message({
            type: "warning",
            showClose: true,
            message: "文件大小不能超过10M!建议上传的文件大小控制在10M以内",
            duration: 2000
          });
          return;
        }
      }
      //判断有没有选择系统
      //    if($('input:radio[name="other_system"]:checked').val() == null ||
      //      $('input:radio[name="other_system"]:checked').val() == undefined)
      //    {
      //      this.$message({
      //        type: 'warning',
      //        showClose: true,
      //        message: '请选择其中一种系统导入!',
      //        duration:2000
      //      });
      //      return;
      //    }

      //判断总剩余客户数量
      if (this.allSurplusCusCounts == 0) {
        this.$message({
          type: "warning",
          showClose: true,
          message: "您的总创建客户数量已超标，请联系客服人员进行确认！",
          duration: 2000
        });
        return;
      }

      //哪一种系统
      var sys_tag = $('input:radio[name="other_system"]:checked').val();

      //上传文件名
      var file_name = $("#system_import").val();
      var lef = this;
      //提交
      var option = {
        type: "POST",
        //      "async": false,
        cache: false,
        url:
          backUrl +
          "cus_addImportCustomerFromSysByExcelBefore.action?token=" +
          token,
        data: {
          customerFileName: file_name, //文件名
          sys_tag: 0, //系统的标识，1是富通
          futong_tag: "", //第四列的信息
          customer_type: "0,0", //客户关系和客户来源
          admin_can_repeat: this.companyparamlist.param159 //系统后台创建客户是否重复,0:不允许  1:允许
        },
        beforeSend: function() {
          lef.$store.state.popupLoading = true;
        },
        complete: function() {
          lef.$store.state.popupLoading = false;
        },
        success: function(msg) {
          if (msg == 1) {
            lef.$message({
              type: "success",
              showClose: true,
              message:
                "文件上传成功，后台正在努力处理中，请耐心等待，稍后会有信息提醒您！",
              duration: 2000
            });
            //切换到查看导入记录
            lef.$emit("lookrecod");
          } else if (msg == -1) {
            lef.$message({
              type: "warning",
              showClose: true,
              message: "导入文件无客户数据，请先填入数据！",
              duration: 2000
            });
          } else if (msg == -2){
            lef.$message({
              type: "warning",
              showClose: true,
              message: "表头信息必须包括 业务员、企业全称、联系人邮箱！",
              duration: 3000
            });
          }
        },
        error: function() {
          lef.$message({
            type: "warning",
            showClose: true,
            message: "出错了，请联系客服进行协助！",
            duration: 2000
          });
        }
      };
      myAjaxSubmit("cus_import_from_system", option);
    },

    clickShow: function() {
      this.showInfos = true;
    },
    helpTip() {
      this.$message({
        type: "warning",
        showClose: true,
        message: "指的是第四列的信息将匹配您的客户分类还是客户状态!",
        duration: 2000
      });
		},
		//切换表头说明
		lookExplainJs(){
			this.lookExplain=!this.lookExplain
		}
  },
  computed: {
    ...mapState({
      domain: state => state.userparamlist,
      companyparamlist: state => state.companyparamlist,
      allSurplusCusCounts: state => state.allSurplusCusCounts
    })
  },
  mounted() {
    //赋值后台访问路径
    this.sysImportUrl = backUrl + "demoDoc/futong.xls";
    this.generImportUrl1 = backUrl + "demoDoc/importCustomerRule2.xls";
    this.generImportUrl = backUrl + "demoDoc/importSystem.xls";

  }
};
</script>
<style lang="less">
/*弹框组件样式*/
/**/

#systemImport {
  /*display: none;*/
  /*padding-top: 10px;*/
  /*padding-left: 20px;*/
}

.crm-oItab-title {
  padding-left: 10px;
}

.crm-input-radio {
  outline: none;
  margin: 0;
  font-size: 12px;
  vertical-align: middle;
}

.crm-cu-import {
  text-align: left;
  /*padding-top: 10px;*/
  /*padding-left: 20px;*/
  margin-top: 10px;
  h4 {
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
  }
}

.createTab .crm-cu-import h4 span {
  font-size: 18px;
}

.createTab .crm-cu-p li {
  margin-top: 10px;
}

.crm-cu-p li .tip-btn {
  display: inline-block;
  border-radius: 2px;
  padding: 0 5px;
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  color: #20a0ff;
  cursor: pointer;
  &:hover {
    background-color: #20a0ff;
    color: #fff;
  }
}

.tip-btn {
  display: inline-block;
  border-radius: 2px;
  padding: 0 5px;
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  color: #20a0ff;
  cursor: pointer;
  &:hover {
    background-color: #20a0ff;
    color: #fff;
  }
}

#ordinaryImport {
  /*display: none;*/
  /*padding-top: 10px;*/
  /*padding-left: 20px;*/
}

.crm-oItab-title {
  border-bottom: 1px solid #ddd;
  text-align: left;
  height: 35px;
  line-height: 35px;
}

.crm-oItab-left {
  text-align: right;
  padding: 0 10px 12px 5px;
  height: 30px;
  line-height: 30px;
  white-space: nowrap;
}

.crm-oItab-right {
  .crm-oItab-left;
  text-align: left;
  a {
    display: inline-block;
    border-radius: 2px;
    padding: 0 2px;
    height: 20px;
    line-height: 20px;
    color: #20a0ff;
    text-decoration: none;
    &:hover {
      color: #fff;
      background-color: #20a0ff;
    }
  }
  .crm-system-example {
    color: #fff;
    background-color: #20a0ff;
    text-decoration: none;
  }
}

.crm-select {
  border: 1px solid #bfcbd9;
  height: 30px;
  line-height: 30px;
  width: 180px;
}
/*富通系统弹框样式*/

.normal-tips {
  .connectInfo {
    width: 500px;
  }
}

.icon-help-copy {
  color: #20a0ff;
  cursor: pointer;
  margin-left: 3px;
}
.curpot{
	cursor: pointer;
}
.curpot:hover{
	color: #20a0ff;
}
.curUp{
	color: #20a0ff
}
.crm-oItab-right .example{
	    background-color: #20a0ff;
}
.crm-oItab-right a{
  color:#fff;
  
}

/*}*/
</style>