<!--
程序版本：V2.0
版权所有：深圳市科飞时速网络科技有限公司
技术支持：Tech@21gmail.com
单元名称：客户·添加新客户·普通导入弹窗
开始时间：2017-11-9
开发人员：陈立珍
最后修改：2018-7-23
备注说明：如需修改请联系开发人员
-->

<template>
  <div id="ordinaryImport">
    <div>
      <form id="cus_beforeimportCustomerFrom" method="post" >
        <table width="100%" border="0" cellspacing="0" cellpadding="0">
          <tbody>
            <tr>
              <td colspan="4" class="crm-oItab-title">
                <b>
                  <span>普通导入</span>
                </b>
                <span class="gray">(普通导入不受日限制)</span>
              </td>
            </tr>
            <tr>
              <td height="8"></td>
            </tr>
            <!--导入文件-->
            <tr>
              <td width="100" class="crm-oItab-left">
                <span>选择文件</span>
              </td>
              <td colspan="3" class="crm-oItab-right">
                <input type="file" id="before_import" name="customerfile" class="uploadBtn" value="浏览" accept=".xls,.xlsx,.csv"/>
      						<i class="icon iconfont icon-help-copy" @click="helpTip" title="导入文件注意事项"></i>
              </td>
            </tr>
            <tr>
              <td width="100" class="crm-oItab-left">
                <span>存储位置</span>
              </td>
              <td colspan="3" class="crm-oItab-right">
                <select id="custype" class="custx">
                  <option value="private">私海</option>
                  <option value="public">公海</option>
                </select>
              </td>
            </tr>
            <tr>
              <td width="115" class="crm-oItab-left">模版下载</td>
              <td colspan="3" class="crm-oItab-right">
      						 <a href="javascript:void(0);" class="example">
      						 	<span title="点击下载普通导入样例"><a :href="generImportUrl">样例</a></span></a>
                <span class="gray">(不严格要求参照该样例,支持xls|xlsx|csv)</span>
              </td>
            </tr>
            <tr>
              <td width="115" class="crm-oItab-left">字段匹配</td>
              <td colspan="3" class="crm-oItab-right">
      						 <a href="javascript:void(0);" class="example">
      						 	<span title="点击下载普通导入样例"><a :href="generImportUrl1">下载</a></span></a>
              </td>
            </tr>
          </tbody>
        </table>
      </form>
    </div>
    <div>
      <table width="100%" cellspacing="0" cellpadding="0" border="0">
        <tbody>
          <tr>
            <td colspan="4" class="crm-oItab-title">
              <b><span>业务信息</span></b>
            </td>
          </tr>
          <tr>
            <td height="8"></td>
          </tr>
          <tr class="myBussinessInfo">
            <td width="115" class="crm-oItab-left">
              <span>客户分类</span>
            </td>
            <td class="crm-oItab-right">
              <select id="classify_select" class="crm-select">
                <option v-for="classify in classifylist"  :value="classify.id" :title="classify.name">{{classify.name1}}</option>
              </select>
            </td>
          </tr>
          <tr class="myBussinessInfo">
            <td width="115" class="crm-oItab-left">
              <span>客户来源</span>
            </td>
            <td class="crm-oItab-right">
              <select id="source_select" class="crm-select">
                <option v-for="source in sourcelist"  :value="source.id" :title="source.name">{{source.name1}}</option>
              </select>
            </td>
          </tr>
          <tr class="myBussinessInfo">
            <td width="115" class="crm-oItab-left">
              <span>客户关系</span>
            </td>
            <td class="crm-oItab-right">
              <select id="relation_select" class="crm-select">
                <option v-for="relation in relationlist"  :value="relation.id" :title="relation.name">{{relation.name1}}</option>
              </select>
            </td>
          </tr>
          <tr class="myBussinessInfo">
            <td width="115" class="crm-oItab-left">
              <span>客户状态</span>
            </td>
            <td class="crm-oItab-right">
              <select id="status_select" class="crm-select">
                <option v-for="status in statuslist" :value="status.id" :title="status.name">{{status.name1}}</option>
              </select>
            </td>
          </tr>
          <tr class="myBussinessInfo">
            <td width="115" class="crm-oItab-left">
              <span>所属行业</span>
            </td>
            <td class="crm-oItab-right">
              <select id="hangye_select" class="crm-select">
                <option v-for="hangye in hangyelist"  :value="hangye.cid" :title="hangye.cname">{{hangye.name}}</option>
              </select>
            </td>
          </tr>
          <tr class="myBussinessInfo">
            <td width="115" class="crm-oItab-left">
              <span>关联产品</span>
            </td>
            <td class="crm-oItab-right">
            	<div style="width: 180px;">
                 <com-product :choiceProductArr="productid" @cofirmProduct="pcpData"></com-product>
            		<!-- <productClassPublic @pcpData='pcpData'></productClassPublic> -->
            	</div>
              <!--<select id="product_select" class="crm-select">
                <option v-for="product in productlist"  :value="product.id" :title="product.name">{{product.name1}}</option>
              </select>-->
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <generImportSelect v-if="crC" @subRefresh="subRefresh" @cntClose="crC=false" :import_classifyid="import_classifyid"
    	 :import_sourceid="import_sourceid" :import_partnerid="import_partnerid"
    	  :import_statusid="import_statusid" :import_hangyeid="import_hangyeid"
    	   :import_productid="import_productid" :excelTableHeaders="excelTableHeaders"
    	    :importFileName="importFileName" :importFilePath="importFilePath" :import_type="import_type"></generImportSelect>
  </div>
</template>
<script>
  import generImportSelect from './createClient_ordinaryImport_select.vue'
  import store from '@/vuex/store'
  import {mapState, mapGetters, mapMutations, mapActions} from 'vuex'
  import productClassPublic from '@/components/productClassPublic'
  import comProduct from "@/components/common_file/com_product/comProduct";
  export default({
    name:'ordinaryImport',
    data(){
      return{
        crC:false,
        import_classifyid:0,//导入的客户分类id
        import_sourceid:0,//导入的客户来源id
        import_partnerid:0,
        import_statusid:0,
        import_hangyeid:0,
        import_productid:0,
        excelTableHeaders:[],//文件第一行列信息
        importFileName:'',//上传文件名称
        importFilePath:'',//服务器存储路径
        import_type:'private',
        generImportUrl:'',
        classifylist:[],//客户分类信息集合
        sourcelist:[],//客户来源信息集合
        relationlist:[],//客户关系信息集合
        statuslist:[],//客户状态集合
        hangyelist:[],//行业集合
        productlist:[],//产品数据集合
        productid:'',
        generImportUrl1:'',//字段匹配路径
      }
    },
    methods:{
      ...mapMutations({
        getCustomerClassifyAll: 'getCustomerClassifyAll',
        getCustomerFromAll: 'getCustomerFromAll',
        getCustomerRelationAll: 'getCustomerRelationAll',
        getCustomerStatusAll: 'getCustomerStatusAll',
        getIndustryType: 'getIndustryType',
        getMajorProductAll: 'getMajorProductAll',
      }),
      pcpData(data){
				this.productid = data
			},
      generImport(){
        //判断是否选择文件
        var selectFile = $("#before_import").val();
        if (selectFile == "" || selectFile == null || selectFile == undefined)
        {
          this.$message({
            type: 'warning',
            showClose: true,
            message: '请选择要上传的文件!',
            duration:2000
          });
          return;
        }

        //判断格式是否正确
        var selectFileDivision = selectFile.split('.');
        var fileFormat = '.' +selectFileDivision[selectFileDivision.length-1];

        if (fileFormat != ".xls" && fileFormat != ".xlsx" && fileFormat != ".csv")
        {
          this.$message({
            type: 'warning',
            showClose: true,
            message: '上传文格式不正确!',
            duration:2000
          });
          return;
        }

        //判断文件大小
        var fileInput = document.getElementById("before_import").files;
        if(fileInput != undefined)
        {
          if(fileInput[0].size > 1024 * 1024 * 2)
          {
            this.$message({
              type: 'warning',
              showClose: true,
              message: '文件大小不能超过2M!',
              duration:2000
            });
            return
          }
        }

        //判断总剩余客户数量
        if(this.allSurplusCusCounts == 0)
        {
          this.$message({
            type: 'warning',
            showClose: true,
            message: '您的总创建客户数量已超标，请联系客服人员进行确认！',
            duration:2000
          });
          return;
        }
        var lef = this;
        var option =
          {
            url: backUrl + 'cus_importCustomerCheeck1.action?token='+token,
            dataType: 'json',
            async: false,
            data: {
              customerFileName:$("#before_import").val()
            },
            beforeSend: function() {
					    lef.$store.state.popupLoading = true;
				    },
				    complete: function() {
					  lef.$store.state.popupLoading = false;
				    },
            success: function (msg) {
              if(msg.result==1)
              {
              	lef.import_classifyid = $("#classify_select").val();
              	lef.import_sourceid = $("#source_select").val();
              	lef.import_partnerid = $("#relation_select").val();
              	lef.import_statusid = $("#status_select").val();
              	lef.import_hangyeid = $("#hangye_select").val();
              	lef.import_productid = lef.productid;
              	lef.importFileName = $("#before_import").val();
              	lef.importFilePath = msg.importFilePath;
              	lef.excelTableHeaders = msg.excelTableHeaders;
              	lef.import_type = $("#custype").val();
              	lef.crC = true;
              }
              else if(msg.result == -1){
                lef.$message({
                  type: 'warning',
                  showClose: true,
                  message: '导入文件无客户数据，请先填入数据！',
                  duration:2000
                });
              }
              else
              {
                lef.$message({
                  type: 'warning',
                  showClose: true,
                  message: '出错了,多次出错后请立即联系客服协助!',
                  duration:2000
                });
              }
            }
          };
        	myAjaxSubmit('cus_beforeimportCustomerFrom',option);
      },
      helpTip(){
        this.$message({
          type: 'warning',
          showClose: true,
          message: '导入时，请注意选择，您文档的每一列，所对应易外销系统的内容!',
          duration:2000
        });
      },
      //处理客户分类
      handleClassify() {
        if(this.customerclassifyall == ''){
          this.getCustomerClassifyAll();
        }
        //this.classifylist.push({name:"请选择",name1:"请选择",id:0});
        var lef = this;
        $.each(this.customerclassifyall,function (index, row) {
        	var name1 = row.name;
        	if(name1.length > 12){
        		name1 = name1.substring(0,12)+"...";
        	}
          lef.classifylist.push({
            name:row.name,
            name1:name1,
            id:row.id
          })
        });
      },
      //处理客户来源
      handleSourcefrom() {
      	//加载客户来源信息
        if(this.customerfromall == ''){
          this.getCustomerFromAll();
        }
        //this.sourcelist.push({name:"请选择",name1:"请选择",id:0});
        var lef = this;
        $.each(this.customerfromall,function (index, row) {
        	var name1 = row.name;
        	if(name1.length > 12){
        		name1 = name1.substring(0,12)+"...";
        	}
          lef.sourcelist.push({
            name:row.name,
             name1:name1,
            id:row.id
          })
        });
      },
      //处理客户关系
      handleRelation() {
      	//加载客户关系信息
        if(this.customerrelationall == ''){
          this.getCustomerRelationAll();
        }
        //this.relationlist.push({name:"请选择",name1:"请选择",id:0});
        var lef = this;
        $.each(this.customerrelationall,function (index, row) {
        		var name1 = row.name;
        	if(name1.length > 12){
        		name1 = name1.substring(0,12)+"...";
        	}
          lef.relationlist.push({
            name:row.name,
            name1:name1,
            id:row.id
          })
        });
      },
      //处理客户状态
      handleStatus() {
      	//加载客户状态
        if(this.customerstatusall == ''){
          this.getCustomerStatusAll();
        }
        //this.statuslist.push({name:"请选择",name1:"请选择",id:0});
        var lef = this;
        $.each(this.customerstatusall,function (index, row) {
        		var name1 = row.name;
        	if(name1.length > 12){
        		name1 = name1.substring(0,12)+"...";
        	}
          lef.statuslist.push({
            name:row.name,
            name1:name1,
            id:row.id
          })
        });
      },
      //处理所属行业
      handleYanghe() {
        var lef = this;
        $.each(this.industryType,function (index, row) {
        	var name = row.cname;
        	if(name.length > 12){
        		name = name.substring(0,12)+"...";
        	}
          lef.hangyelist.push({
            cname:row.cname,
            name:name,
            cid:row.cid
          })
        });
      },
      //处理关联产品
      handleProduct() {
        if(this.majorproductall == ''){
          this.getMajorProductAll();
        }
        //this.productlist.push({name:"请选择",name1:"请选择",id:0});
        var lef = this;
        $.each(this.majorproductall,function (index, row) {
        	var name1 = row.name;
        	if(name1.length > 12){
        		name1 = name1.substring(0,12)+"...";
        	}
          lef.productlist.push({
            name:row.name,
            name1:name1,
            id:row.id
          })
        });
      },
      //子vue调用刷新列表
      subRefresh(){
      	//切换到查看导入记录
        this.$emit('lookrecod');
      }
    },
    computed: {
      ...mapState({
        customerclassifyall: state => state.customerclassifyall,
        customerfromall: state => state.customerfromall,
        customerrelationall: state => state.customerrelationall,
        customerstatusall: state => state.customerstatusall,
        industryType: state => state.hangyeArr,
        majorproductall: state => state.majorproductall,
        allSurplusCusCounts: state => state.allSurplusCusCounts,
      }),
    },
    mounted(){
      //赋值后台访问路径
      this.generImportUrl = backUrl + 'demoDoc/importCustomers.xls';
      this.generImportUrl1 = backUrl + 'demoDoc/importCustomerRule1.xls';
      this.handleClassify();
      this.handleSourcefrom();
      this.handleRelation();
      this.handleStatus();
      this.handleYanghe();
      this.handleProduct();
    },
    components:{
      generImportSelect,productClassPublic,comProduct
    },
  })
</script>
<style rel="stylesheet/scss" lang="less">
  #ordinaryImport{
    /*display: none;*/
    /*padding-top: 10px;*/
    /*padding-left: 20px;*/
  }
  .crm-oItab-title{
    border-bottom:1px solid #ddd;
    text-align: left;
    height:35px;
    line-height: 35px;;
  }
  .myBussinessInfo{
    display: inline-block;
    &:nth-child(odd){
      float:left;
    }
    &:nth-child(even){
      float:right;
      margin-right:25px;
    }
  }
  .crm-oItab-left{
    text-align: right;
    padding:0 10px 12px 5px;
    height:30px;
    line-height: 30px;
    /*width:115px;*/
  }
  .crm-oItab-right{
    .crm-oItab-left;
    text-align:left;
    a{
      display: inline-block;
      border-radius: 2px;
      padding: 0 2px;
      height: 20px;
      line-height: 20px;
      color: #20a0ff;
      text-decoration: none;
      &:hover{
        background-color:#20a0ff;
        color:#fff;
       }
    }
    .example{
      background-color:#20a0ff;
      a{
        color:#fff;
      }
    }
  }
  .crm-select{
    border: 1px solid #bfcbd9;
    height: 30px;
    line-height: 30px;
    width: 180px;
  }
  .uploadBtn{
    width: 180px;
    font-size: 12px;
    border: 1px solid #ddd;
    box-sizing: content-box;
    vertical-align: middle;
    padding:0!important;
  }
  .icon-help-copy{
    color:#20a0ff;
    cursor:pointer;
    margin-left: 3px;
  }
  .table_class{
    margin-top: 20px;
  }
</style>
