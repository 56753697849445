<!--
程序版本：V2.0
版权所有：深圳市科飞时速网络科技有限公司
技术支持：Tech@21gmail.com
单元名称：客户·侧边栏页面
开始时间：2017-11-9
开发人员：陈立珍
最后修改：2018-7-23
备注说明：如需修改请联系开发人员
-->

<template>
  <div id="crm-index">
  	<!--左侧边栏-->
    <div class="left-tab">
      <div class="product-name"><i class="icon iconfont icon-kehuguanli"></i><span>客户管理系统(CRM)</span></div>
      <!--导航栏·新建客户/公海客户/客户回收站-->
      <ul class="user-list">
        <li @click="openCrC()" class="crm-user-list-li"><i class="icon iconfont icon-user newClient-icon"></i><span>新建客户</span></li>
          <li v-show="showPCus" class="crm-user-list-li" @click="selectCommonSea" :class="$route.path==='/crm/commonClient'?'selected':''">公海客户
            <span class="red-num">({{pCusNum}})</span>
          </li>
          <li class="crm-user-list-li" @click="selectRecycle" :class="$route.path==='/crm/recycle'?'selected':''">客户回收站
            <span class="red-num">({{rCusNum}})</span>
          </li>
      </ul>
      <!--全部客户-->
      <div class="tab-list">
        <router-link to="/crm/allClient" class="check-a">
          <div class="tab-title" @click="showList()" :class="isSelected&&$route.path==='/crm/allClient'?'selected':''">
            <i class="icon iconfont icon-arrow-right arrow-bg" :class="[show?'rotate':'']" style="display: inline-block;"></i>
            <span class="drop-down_title">全部客户</span>
            <span class="red-num title-num">({{mCusNum}})</span>
          </div>
        </router-link>
         <!--全部客户子分类-->
        <el-collapse-transition>
          <div class="all-client-list client-lists" v-show="show">
            <ul class="select-box ">
              <router-link to="/crm/allClient" v-for="(li,index) in cusClassifyList" class="check-a" :key="'li'+index">
                <li class="crm-tab-list-li" @click="classify_click($event,li.id)">
                  <div class="maxCus_width hide-name" :title="li.name">{{li.name}}</div>
                  <div class="red-num" style="float: left;" :id="'cus_classify'+li.id">(0)</div>
                </li>
              </router-link>
            </ul>
          </div>
        </el-collapse-transition>
      </div>
      <div class="tab-list">
      	<!--客户状态-->
        <div class="tab-title" @click="showListStatus()">
          <i class="icon iconfont icon-arrow-right arrow-bg" :class="[show2?'rotate':'']" style="display: inline-block;"></i>
          <span class="drop-down_title">客户状态</span>
        </div>
        <!--客户状态的子分类-->
        <el-collapse-transition>
          <div class="client-status-list status-lists" v-show="show2">
            <ul class="select-box">
              <router-link to="/crm/allClient" v-for="(sta,index ) in cusStatusList" class="check-a" :key="'sta'+index">
                <li class="crm-tab-list-li" @click="status_click($event,sta.id)">
                  <div class="maxCus_width hide-name" :title="sta.name">{{sta.name}}</div>
                  <div class="red-num" style="float: left;" :id="'cus_status'+sta.id">(0)</div>
                </li>
              </router-link>
            </ul>
          </div>
        </el-collapse-transition>
      </div>
      <ul class="client-origin select-box">
      	<!--我的共享客户-->
        <router-link to="/crm/allClientOrigin_myShare">
          <li class="crm-client-origin-li crm-tab-list-li"
              :class="$route.path==='/crm/allClientOrigin_myShare'?'selected':''"
              @click="closeList">
            <span>我的共享客户</span>
            <span class="red-num">({{myShareNum}})</span>
          </li>
        </router-link>
        <!--同事共享给我的-->
        <router-link to="/crm/allClientOrigin_colleagueShare" >
          <li class="crm-client-origin-li crm-tab-list-li" :class="$route.path==='/crm/allClientOrigin_colleagueShare'?'selected':''" @click="closeList">
            <span>同事共享给我的</span>
            <span class="red-num">({{otherShareNum}})</span>
          </li>
        </router-link>
        <!--已入客户库联系人-->
        <router-link to="/crm/allClientOrigin_inWarehouse">
          <li class="crm-client-origin-li crm-tab-list-li"
              :class="$route.path==='/crm/allClientOrigin_inWarehouse'||$route.path==='/crm/allClientOrigin_locationLinkMan'?'selected':''"
              @click="closeList">
            <span>已入客户库联系人</span>
            <span class="red-num">({{yesLinkNum}})</span>
          </li>
        </router-link>
        <!--未入客户库联系人-->
        <router-link to="/crm/allClientOrigin_unInWarehouse">
          <li class="crm-client-origin-li crm-tab-list-li" :class="$route.path==='/crm/allClientOrigin_unInWarehouse'?'selected':''" @click="closeList">
            <span>未入客户库联系人</span>
            <span class="red-num">({{noLinkNum}})</span>
          </li>
        </router-link>
      </ul>
    </div>
    <!--右侧栏-->
    <div class="right-tab">
      <transition name="fade">
        <router-view
        	:bigType="bigType"
        	:bigTypeValue="bigTypeValue"
        	:my-title="myTitle"
          :sort-num = 'sortNum'
          :filter-num="filterNum"
          @returnCrm = "returnCrm"
          @listFilter1="listFilter1"
          @listFilter2="listFilter2"
          @listFilter3="listFilter3"
          @listFilter4="listFilter4"
          @listFilter53="listFilter53">
        </router-view>
      </transition>
    </div>
    <!--新建客户组件-->
    <create-client v-if="crC" @close="close" @successclose="successclose"></create-client>
  </div>
</template>

<script>

  // 导入组件
  import createClient from './createClient/createClient.vue'
  import crmcontent from './CommonSea.vue'

  import store from '@/vuex/store'
  import {mapState, mapGetters, mapMutations, mapActions} from 'vuex'

  export default {
    name: 'crm-index',
    data(){
      return{
      	mCusNum:0,//全部客户数量
      	pCusNum:0,//公海客户数量
      	rCusNum:0,//回收站客户数量
      	myShareNum:0,//我的共享客户数量
      	otherShareNum:0,//别人共享客户数量
      	yesLinkNum:0,//已入库联系人数量
      	noLinkNum:0,//未入库联系人数量
      	bigType:0,//左侧大类标识,0客户分类展开,1客户分类收缩,2客户分类,3客户状态
      	bigTypeValue:0,//bigType对应的值
				showPCus:false,//是否显示公海客户,false隐藏,true显示
				show:true,//控制客户分类是否展开
	      show2:false,//控制客户状态是否展开


      	filterNum:{
        	num:0
      	},
	      isSelected:true,
	      crC:false,
	      sortNum:1,
	      myTitle:'',
	      array:[],
	      clientClassify:[
	        {name:'重点跟进客户',num:12},
	        {name:'默认分类',num:12},
	        {name:'重点意向客户',num:12},
	        {name:'一般潜在客户',num:12},
	        {name:'阿里',num:12},
	        {name:'美国客户',num:12},
	        {name:'JP',num:12},
	        {name:'KP',num:12}
	      ],
	      clientStatus:[
	        {name:'收集状态',num:12},
	        {name:'开发状态',num:12},
	        {name:'跟进状态',num:12},
	        {name:'潜在客户',num:12},
	        {name:'意向客户',num:12},
	        {name:'成交客户',num:12}
	      ],
      courseList:[
        {
          id:1,
          name:"报价"
        },
        {
          id:2,
          name:"无"
        },
        {
          id:3,
          name:"订单"
        },
        {
          id:4,
          name:"咨询"
        },
        {
          id:1,
          name:"样品"
        },
      ],
      dataList:[],
      cusClassifyList:[],
      cusStatusList:[],
      clickUserSort:false,//当前是否点击
    }
  },
  computed: {
    ...mapState({
      userinfo: state => state.userinfo,
      loadCusNumTag: state=> state.loadCusNumTag,
      customerclassifyall: state=> state.customerclassifyall,
      customerstatusall: state=> state.customerstatusall,
    }),
  },
  components:{
    createClient,
    crmcontent
  },
  methods:{
    ...mapMutations({
	    getCustomerClassifyAll:'getCustomerClassifyAll',//加载客户分类
      getCustomerStatusAll:'getCustomerStatusAll',//加载客户状态
	  }),
	  //加载左侧总客户数
	  cus_loadcustomernum(){
	  	var lef = this;
	  	$.ajax({
				type : "POST",
				url : backUrl+ "cus_findCustomerNum.action",
				dataType : "json",
				cache : false,
				async : true,
				data : {
					token: token,
					userids : lef.userinfo.user_id
				},
				success : function(msg) {
					lef.mCusNum = msg;
				}
			});
	  },
	  //加载左侧公海库客户数
	  cus_loadpcustomernum(){
	  	var lef = this;
	  	//只有企业级参数才加载公海库信息
	  	if(lef.userinfo.param_level == 0){
	  		$.ajax({
					type : "POST",
					url : backUrl + "cusCP_getPCusCount.action",
					dataType : "json",
					cache : false,
					async : true,
					data : {
						token: token,
						is_yunying: lef.userinfo.is_yunying,
					},
					success : function(msg) {
						lef.pCusNum = msg;
					}
				});
	  	}
	  },
	  //加载左侧我的回收站客户数量
	  cus_loadrcustomernum() {
	  	var lef = this;
			$.ajax({
				type : "POST",
				url : backUrl + "cus_findMyRCustomerNum.action",
				dataType : "json",
				data : {
						token: token,
				},
				async : true,
				cache : false,
				success : function(msg) {
					lef.rCusNum = msg;
				}
			});
		},
	  //加载左侧我共享的客户量
	  cus_myshareNum() {
	  	var lef = this;
			$.ajax({
				type : "POST",
				url : backUrl + "cusCC_findMyShareCustomerNum.action",
				dataType : "json",
				data : {
						token: token,
				},
				cache : false,
				async : true,
				success : function(msg) {
					lef.myShareNum = msg;
				}
			});
		},
		//加载左侧别人共享给我的客户量
		shareGiveMeNum() {
			var lef = this;
			$.ajax({
				type : "POST",
				cache : false,
				async : true,
				data : {
						token: token,
				},
				url : backUrl + "cusCC_findShareCustomernum.action",
				success : function(msg) {
					lef.otherShareNum = msg;
				}
			});
		},
		//加载左侧联系人数(已入客户联系人,未入客户联系人)
	  cus_loadlinkmanum(type) {
	  	var lef = this;
			$.ajax({
				type : "POST",
				url : backUrl + "cus_findLinkManNum.action",
				dataType : "json",
				cache : false,
				async : true,
				data : {
					token: token,
					userids : lef.userinfo.user_id,
					type : type
				},
				success : function(msg) {
					if(type == 0)// 已入库联系人量
					{
						if(msg>99999){
							lef.yesLinkNum = 99999+"+";
						}else{
							lef.yesLinkNum = msg;
						}
					}
					else// 未入库联系人量
					{
						if(msg>99999){
							lef.noLinkNum = 99999+"+";
						}else{
							lef.noLinkNum = msg;
						}

					}
				}
			});
		},
		//加载左侧各客户状态对应的客户数
		cus_loadStatusCounts() {
			var lef = this;
			//重值各状态对应的客户数
			$.each(lef.customerstatusall, function(s, row) {
				$("#cus_status" + row.id).html("(0)");
			});
			$.ajax({
				type : "POST",
				url : backUrl + "cusST_findCrmStatusCusCounts.action",
				dataType : "json",
				cache : false,
				data : {
					token: token,
					userids : lef.userinfo.user_id,
				},
				success : function(counts) {
					$.each(counts, function(s, row) {
						if(row > 9999){
							$("#cus_status" + s).html("(" + 9999 + "+)");
						}else{
							$("#cus_status" + s).html("(" + row + ")");
						}

					});
				}
			});
		},
		//加载左侧各客户分类对应的客户数
		cus_loadClassifyCounts() {
			var lef = this;
			//重值各状态对应的客户数
			$.each(lef.cusClassifyList, function(s, row) {
				$("#cus_classify" + row.id).html("(0)");
			});
			$.ajax({
				type : "POST",
				url : backUrl + "cusC_findCrmClassifyCusCounts.action",
				dataType : "json",
				cache : false,
				async : true,
				data : {
					token: token,
					userids : lef.userinfo.user_id,
				},
				success : function(msg) {
					$.each(msg, function(s, row) {
						if(row > 9999){
							$("#cus_classify" + s).html("(" + 9999 + "+)");
						}else{
							$("#cus_classify" + s).html("(" + row + ")");
						}
					});
				}
			});
		},
		//客户分类点击事件
		classify_click(event,id){
			this.bigType = 2;
			this.bigTypeValue = id;
			this.isSelected = false;
			$('.crm-tab-list-li').removeClass('selected');
      $($(event.currentTarget)).addClass('selected');
		},
		//客户状态点击事件
		status_click(event,id){
			this.bigType = 3;
			this.bigTypeValue = id;
			this.isSelected = false;
			$('.crm-tab-list-li').removeClass('selected');
      $($(event.currentTarget)).addClass('selected');
		},

    //选中公海客户
    selectCommonSea(){
      $('.crm-tab-list-li').removeClass('selected');
      this.$router.push({path:'/crm/commonClient'});
      this.closeList();
      this.isSelected = false;
    },
    //选中回收站
    selectRecycle(){
      $('.crm-tab-list-li').removeClass('selected');
      this.$router.push({path:'/crm/recycle'});
      this.closeList();
      this.isSelected = false;
    },
    //选中全部客户
    showList:function(){
      if(!this.show){//客户分类展开
      	this.bigType = 0;
      	this.bigTypeValue = 0;
        this.show2=false;
      }else{//客户分类收缩
      	this.bigType = 1;
      	this.bigTypeValue = 0;
      }
      this.isSelected = true;//选中状态
      $('.crm-tab-list-li').removeClass('selected');
      this.show=!this.show;
      let title = new Object;
      title.name = '全部客户'
      this.myTitle = title;
    },
  	//选中客户状态
    showListStatus:function(){
      if(!this.show2){
        this.show = false;
      }
      this.show2=!this.show2
    },
    //收起下拉列表
    closeList(){
      this.show2 = false;
      this.show = false;
    },
    //打开新建客户弹窗
    openCrC:function(){
      this.$store.commit('FROM_COUNTRY_EDIT',false)
      this.$store.commit('CUR_COUNTRY','')
      this.crC = true;
    },
    //关闭新建客户弹窗
    close(statu){
      this.crC=statu;
    },
    //添加客户成功后返回
    successclose(){
     	this.crC = false;
     	 this.isSelected = true;//选中状态
      $('.crm-tab-list-li').removeClass('selected');
      this.show=true;
      let title = new Object;
      title.name = '全部客户'
      this.myTitle = title;
     	this.cus_loadcustomernum();//刷新全部客户数量
     	this.cus_loadlinkmanum(0);//刷新已入库联系人数量
     	this.cus_loadStatusCounts();//刷新各客户状态数量
			this.cus_loadClassifyCounts();//刷新各客户分类数量
			if(this.bigType>0){
				this.bigType=0;
			}else{
				this.bigType=1;
			}
      this.bigTypeValue = 0;
    },
    listFilter1(){
      this.filterNum.num = 1;
      this.itemNum = -1;
    },
    listFilter2(){
      this.filterNum.num = 2;
    	this.itemNum = -1;
    },
    listFilter3(){
      this.filterNum.num = 3;
      this.itemNum = -1;
    },
    listFilter4(){
      this.filterNum.num = 4;
      this.itemNum = -1;
    },
    listFilter5(){
      this.filterNum.num = 5;
      this.itemNum = -1;
    },
    listFilter53(){
    	this.filterNum.num = 53;
      this.itemNum = -1;
    },
    returnCrm(){
      let title = new Object;
      title.name = '全部客户'
      this.myTitle = title;
      this.isSelected = true;
      $('.crm-tab-list-li').removeClass('selected')
      this.$router.push({path:'/crm/allClient'});
      $('.client-lists').animate({'height':'223px'},200);
      this.show = true;
    }
  },
  beforeRouteLeave(to, from, next) {
		this.$store.state.routerLoading = true
		setTimeout(function(){
			next();
		},20)
	},
	activated() {
			this.$store.state.routerLoading = false
		},
  mounted(){
  	this.$store.state.routerLoading = false
  	if(this.userinfo.param_level == 0){//企业级显示公海客户
  		this.showPCus = true;
  	}else{//个人级隐藏公海客户
  		this.showPCus = false;
  	}
   	this.cus_loadcustomernum();//加载左侧总客户数
   	this.cus_loadpcustomernum(); //加载左侧公海库客户数
    this.cus_loadrcustomernum(); //加载左侧我的回收站客户数量
    this.cus_myshareNum(); //加载左侧我共享的客户量
    this.shareGiveMeNum();//加载左侧同事共享给我的的客户量
		this.cus_loadlinkmanum(0);//加载左侧联系人数 已入客户联系人
		this.cus_loadlinkmanum(1);//加载左侧联系人数 未入客户联系人
		let _this = this;
		//加载客户分类信息
		if(this.customerclassifyall == ''){
  		this.getCustomerClassifyAll();
  	}
		$.each(_this.customerclassifyall, function(i,row) {
			_this.cusClassifyList.push({
				name:row.name,
				id:row.id,
			})
		});
		_this.cusClassifyList.push({
				name:'未分类',
				id:0,
			})
		//加载客户状态
		if(this.customerstatusall == ''){
  		this.getCustomerStatusAll();
  	}
		$.each(_this.customerstatusall,function(i,row){
			_this.cusStatusList.push({
				name:row.name,
				id:row.id,
			})
		})
		//加载侧边栏数据
		this.cus_loadStatusCounts();
		//加载左侧边栏数量
		this.cus_loadClassifyCounts();
    let my_title = new Object;
    my_title.name = '全部客户';
    this.myTitle = my_title;
  },
  watch:{
  	loadCusNumTag(){
  		if(this.loadCusNumTag == 0){//刷新全部客户数量&&各分类客户数量&&各状态客户数量&&已入库联系数量
  			this.cus_loadcustomernum();
  			this.cus_loadClassifyCounts();
  			this.cus_loadStatusCounts();
  			this.cus_loadlinkmanum(0);
  		}else if(this.loadCusNumTag == 1){//刷新公海客户数量&&全部客户数量&&各分类客户数量&&各状态客户数量&&已入库联系数量
  			this.cus_loadpcustomernum();
  			this.cus_loadcustomernum();
  			this.cus_loadClassifyCounts();
  			this.cus_loadStatusCounts();
  			this.cus_loadlinkmanum(0);
  		}else if(this.loadCusNumTag == 2){//刷新公海客户数量
  			this.cus_loadpcustomernum();
  		}else if(this.loadCusNumTag == 3){//刷新回收站客户数量&&全部客户数量&&各分类客户数量&&各状态客户数量&&已入库联系数
  			this.cus_loadrcustomernum();
  			this.cus_loadcustomernum();
  			this.cus_loadClassifyCounts();
  			this.cus_loadStatusCounts();
  			this.cus_loadlinkmanum(0);
  		}else if(this.loadCusNumTag == 4){//刷新我的共享客户数量
  			this.cus_myshareNum();
  		}else if(this.loadCusNumTag == 5){//刷新别人共享给我客户数量
  			this.shareGiveMeNum();
  		}else if(this.loadCusNumTag == 6){//刷新未入库联系人数量&&全部客户数量&&各分类客户数量&&各状态客户数量&&已入库联系数量
  			this.cus_loadlinkmanum(1);
  			this.cus_loadcustomernum();
  			this.cus_loadClassifyCounts();
  			this.cus_loadStatusCounts();
  			this.cus_loadlinkmanum(0);
  		}else if(this.loadCusNumTag == 7){//刷新各分类客户数量&&各客户状态数量
  			this.cus_loadClassifyCounts();
  			this.cus_loadStatusCounts();
  		}else if(this.loadCusNumTag == 8){//刷新已入库联系数量
  			this.cus_loadlinkmanum(0);
  		}else if(this.loadCusNumTag == 9){//刷新未入库联系数量
  			this.cus_loadlinkmanum(1);
  		}else if(this.loadCusNumTag == 10){//刷新未入库联系人数量&&已入库联系人数量
  			this.cus_loadlinkmanum(1);
  			this.cus_loadlinkmanum(0);
  		}
  		this.$store.state.loadCusNumTag = -1;//重置默认状态
  	},
  },
  destroyed(){
    console.log(document.getElementById("crmCommCss"));
  },
  };
</script>
<style lang="less">
  @backGray:eef1f6
  select,input{  border-color:#bfcbd9!important;  border-radius: 2px!important;}
  select{min-height: 21px;}
  *{box-sizing:border-box;}
  body{overflow: hidden;margin:0;  }
  ul{padding:0;margin:0;list-style:none;}
  a{text-decoration: none;color:#444;}
  .fade-enter-active,.fade-leave-active{
    transition:opacity 0.5s;
  }
  .fade-enter,.fade-leave-to{
    opacity:0;
  }
  .v-modal{
    display: none;
  }
  /*日历图标*/
  /*.el-input__icon{*/
    /*display: none;*/
  /*}*/
  .el-dialog__wrapper,.el-message-box__wrapper{
    background:rgba(0, 0, 0, 0.2);
  }
  .el-checkbox__inner{
    z-index:0 !important;
  }
  .paging{
    text-align: center;
    margin-top: 10px;
  }
  /*弹框取消按钮*/
  .MBcancelBtn{
    float:right;
    margin-left:10px !important;
  }
  #crm-content{
    /*overflow: auto;*/
  }
  /*弹窗模块展开收缩*/
  .crm-witch{
    float:right;
  }
  /*自动补全下拉框样式*/
  .ui-menu{
    z-index:2100;
    height:200px;
    overflow:auto;
    overflow-x:hidden;
  }
  #crm-index{
    font-family: PingFangSC-Light, "Microsoft YaHei", "Hiragino Sans GB", "WenQuanYi Micro Hei", sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
    max-width:1920px;
    min-width:100%;

    display: flex;
    height: 100%;
    // border: 1px solid black;
  }
  .index-icon{
    float:left;
    cursor:pointer;
    margin-top:-1px;
  }
  .left-tab{
    overflow: auto;
    color:black;
    user-select: none;
    // position:fixed;
    // top:55px;
    // bottom:0;
    // left:0;
    width:201px;
    border-left:1px solid #ddd;
    border-right:1px solid #ddd;
    background-color:#FCFCFC;
  }
  .right-tab{
    width: calc(100% - 201px);
    position: relative;
  }

  .newClient-icon{
    margin-right:5px;
  }
  .product-name{
    height:40px;
    line-height:40px;
    font-size:14px;

    color:#1562b4;
    background-color:#e6e6e6;
    text-align: center;
  }
  .product-name .crm-name-icon{
    float: left;
    width: 22px;
    margin-top: 8px;
    height: 25px;
    background: url("../../../static/img/common/icon.png") no-repeat 0px 2px;
  }
  .user-list{
  a{
    color:#fff;
    text-decoration: none;
  }
  }
  .user-list .crm-user-list-li{
    background-color:#1965b6;
    color:#fff;
    margin-top:10px;
    height:30px;
    line-height: 30px;
    /*text-align:center;*/
    padding-left:26px;
    font-size:14px;

    cursor:pointer;
  }
  .user-list .crm-user-list-li:hover{
    background-color:#084f9a;
  }
  .red-num{
    color:red;
  }
  .maxCus_width{
  	max-width: 95px;
  	float: left;
  }
  .hide-name{
  	overflow: hidden;
text-overflow:ellipsis;
white-space: nowrap;
  }
  .tab-list{
    font-size:12px;

    margin-top:10px;
    border-right:0;
    border-left:0;
    text-align: left;
    background-color:#FCFCFC;
    a{
      color:#444;
    }
  }
  .tab-list .tab-title{
    padding-left:26px;
    font-weight:bold;
    height:28px;
    line-height: 28px;
    .arrow-bg{
      vertical-align: middle;
    }
    .drop-down_title{
      vertical-align: middle;
    }
    .rotate{
      display: inline-block;
      transform: rotate(90deg);
      vertical-align: middle;
    }
  }
  .tab-list .icn1,.tab-list .icn2{
    display: inline-block;
    vertical-align: middle;
    width: 9px;
    height: 9px;
    margin-right:5px;
    background:url("../../../static/img/crm_images/icon/minus.png") no-repeat;
  }
  .tab-list .icn2{
    background:url("../../../static/img/crm_images/icon/plus.png") no-repeat;
  }
  .title-num{
    font-weight:normal;
    vertical-align: middle;
  }
  .tab-list .crm-tab-list-li{
    height:28px;
    line-height: 28px;
    text-align:left;
    padding-left:46px;
  }
  .left-tab .selected{
    background-color:#084f9a;
    color:#fff;
  }
  .tab-list .crm-tab-list-li:hover,.tab-list .tab-title:hover{
    cursor:pointer;
    background-color:#084f9a;
    color:#fff;
  }
  .left-tab .client-origin{
    box-sizing:border-box;
    border-top:1px solid #ddd;
    a{
      color:#444;
    }
  }
  .left-tab .client-origin .crm-client-origin-li{
    font-size:12px;
    height:30px;
    line-height: 30px;
    text-align:left;
    padding-left:26px;
    cursor:pointer;
  }
  .left-tab .client-origin .crm-client-origin-li:hover{
    background-color:#084f9a;
    color:#fff;
  }

  .red{
    color:red;
  }
  .el-time-spinner__item{
    line-height: 10px;
  }
  /*无数据样式*/
  .noData-box{
    text-align: center;
    .noData-bg{
      width:227px;
      height:64px;
      text-align: center;
      margin:0 auto;
      margin-top:20px;
      background:url("../../../static/img/common/hc.png") no-repeat;
    }
    .noData{
      display: inline-block;
      font-size: 25px;
      color: #d7d7d7;
      margin-top: 20px;
    }
  }
  .client-lists,.status-lists{
    overflow: hidden;
  }
  /*input[type='text']:focus{*/
    /*border-color: #66afe9;*/
    /*box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);*/
  /*}*/
  .f-content{
    resize:none;
    width: 360px;
    height:100px;
    font-size: 12px;

    font-family: "Microsoft yahei";
    padding: 5px 5px;
    overflow: auto;
    border: 1px solid #e0e0e0;
    cursor: auto;
    margin: 5px 0;
  }
  #newfollow{
    .alertContent{
      .el-dialog{
        width:700px!important;
        .el-dialog__body{
          height:400px!important;
          padding:0;
          font-size:12px;

          overflow: auto;
        }
      }
    }
  }
</style>
<style lang="less" src="../../less/crm_less/common_animation.less"></style>

<style src="@/css/reset.css"></style>
