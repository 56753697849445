<!--
程序版本：V2.0
版权所有：深圳市科飞时速网络科技有限公司
技术支持：Tech@21gmail.com
单元名称：客户·添加新客户·系统导入中富通系统查看弹窗
开始时间：2017-11-9
开发人员：陈立珍
最后修改：2018-7-23
备注说明：如需修改请联系开发人员
-->

<template>
  <div id="crm-connectInfo" >
    <!--<div class="dialog-masks"></div>-->
    <div class="connectInfo">
      <!--<div class="cnt-head">-->
        <!--<span class="cnt-title">{{cntTitle}}</span>-->
        <!--<span class="el-icon-close" @click="cntClose"></span>-->
      <!--</div>-->
      <el-dialog
        :title="cntTitle"
        class='yourName layer-systemImp dialogTwo'
        size="tiny"
        :visible.sync="test"
        :before-close="cntClose"
        :modal="false"
        :modal-append-to-body="true"
        :lock-scroll="true"
        :close-on-click-modal="false">
        <div>
          <ul class="tips-li">
            <li>
              <span>1.根据您选择的第4列的信息进行匹配客户分类或客户状态,否则取默认值;</span>
            </li>
            <li>
              <span>2.客户等级一般对应客户星级3颗星,客户等级重要对应客户星级5颗星;</span>
            </li>
            <li>
              <span>3.其它业务信息将取默认值;</span>
            </li>
            <li>
              <span>4.重复客户判断只受限于后台管理-参数控制;</span>
            </li>
          </ul>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button  @click="cntClose" style="margin-bottom:10px;">取 消</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
  export default{
    name:"cm-more-info",
    data(){
      return{
        test:true,
        value1:null
      }
    },
    props:[
      'cntTitle',
      'hasButton'
    ],
    methods:{
    	//触发父组件关闭的弹窗
      cntClose:function(){
        this.$emit('cntClose',false)
      },
      //确认
      cntConfirm:function(){
        this.$emit('cntConfirm')
      }
    },
    mounted(){
      function clearTransform(){
        $('.el-dialog').css({
          'transform': 'none',
        })
      };
      dialogDrag();
      clearTransform(dialogCenter());
    }
  }
</script>

<style lang="less">

  .connectInfo {
    .layer-systemImp{
      .el-dialog{
        width:500px;
       }
    }
    input{
      margin:0;
    }
    .cnt-head{
      overflow: hidden;
      line-height: 14px;
      font-size:14px;

      background-color: #20A0FF;
      padding: 10px 10px;
      text-align: left;
      color:#fff;
      .el-icon-close{
        float:right;
        cursor:pointer;
        font-size:14px;

      }
    }
    .tips-li{
      padding: 10px 20px;
      text-align: left;
      height: 150px;
      li{
        line-height:24px;
        color:red;
        font-size:14px;

      }
    }
    .dialog-footer{
      margin-bottom:0;
    }
  }

</style>
