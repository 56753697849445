<!--
程序版本：V2.0
版权所有：深圳市科飞时速网络科技有限公司
技术支持：Tech@21gmail.com
单元名称：客户·添加新客户弹窗
开始时间：2017-11-9
开发人员：陈立珍
最后修改：2018-11-12
备注说明：如需修改请联系开发人员
-->

<template>
	<div id="createClient">
		<!--<div class="dialog-masks"></div>-->
		<!--<div class="createTab">-->
		<el-dialog title="添加新客户" class='yourName dialogOne createCl' :visible.sync="test" :before-close="close" :modal="false" :modal-append-to-body="true" :lock-scroll="true" :close-on-click-modal="false">
			<popupLoading></popupLoading>
			<div class="crm-create-box">
				<div class="ct-con-box">
					<div class="createTab-title">
						<ul>
							<li v-for="(item,$index) in lists" @click="selectTitle(item,$index)" :class="[titleNum == $index?'select-active':'unselect-active1']">
								{{item.text}}
							</li>
						</ul>
					</div>
				</div>
				<!--引入手工创建/普通导入/系统导入/导入记录组件-->
				<div class="crm-alert-tab">
					<newClientByhand v-if="currentTab == 'tab1'" keep-alive ref="tab" @addcusclose="addcusclose" @lookrecod="lookrecod" :chooseProId="chooseProid"></newClientByhand>
					<ordinaryImport v-if="currentTab == 'tab2'" keep-alive ref="tab" @addcusclose="addcusclose" @lookrecod="lookrecod" :chooseProId="chooseProid"></ordinaryImport>
					<div v-if="userinfo.login_id == 'adm'">
						<systemImport v-if="currentTab == 'tab3'" keep-alive ref="tab" @addcusclose="addcusclose" @lookrecod="lookrecod" :chooseProId="chooseProid"></systemImport>
						<importRecords v-if="currentTab == 'tab4'" keep-alive ref="tab" @addcusclose="addcusclose" @lookrecod="lookrecod" :chooseProId="chooseProid"></importRecords>
					</div>
					<div v-if="userinfo.login_id != 'adm'">
						<importRecords v-if="currentTab == 'tab3'" keep-alive ref="tab" @addcusclose="addcusclose" @lookrecod="lookrecod" :chooseProId="chooseProid"></importRecords>
					</div>

					<!--<component :is="currentTab" keep-alive ref="tab" @addcusclose="addcusclose" @lookrecod="lookrecod" :chooseProId="chooseProid"></component>-->
				</div>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" @click="submit">确 定</el-button>
				<el-button @click="close">取 消</el-button>
			</div>
		</el-dialog>
		<!--</div>-->
	</div>
</template>
<script>
	import ordinaryImport from './createClient_ordinaryImport.vue' //普通导入功能文件
	import systemImport from './createClient_systemImport.vue' //系统导入功能文件
	import importRecords from './createClient_importRecords.vue' //导入记录功能文件
	import newClientByhand from './createClient_newClientByhand.vue' //手工创建功能文件
	import store from '@/vuex/store'
	import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
	import popupLoading from '@/components/publicComponent/popupLoading'
	export default({
		name: 'createClient',
		data() {
			return {
				test: true,
				currentTab: 'tab1',
				titleNum: 0,
				lists: [], //导航栏按钮
				chooseProid: "",
			}
		},
		components:{
	    newClientByhand,
	    ordinaryImport,
	    systemImport,
	    importRecords,
	    popupLoading
	  },
//		components: {
//			tab1: newClientByhand,
//			tab2: ordinaryImport,
//			//tab3: importRecords,
//			tab3:systemImport,
//			tab4:importRecords,
//			'popupLoading': popupLoading
//		},
		computed: {
			...mapState({
				userinfo: state => state.userinfo,
				userparamlist: state => state.userparamlist,
				followupwayall: state => state.followupwayall,
				daySurplusCusCounts: state => state.daySurplusCusCounts,
				allSurplusCusCounts: state => state.allSurplusCusCounts,
			}),
		},
		methods: {
			...mapMutations({
				getFollowupWayAll: 'getFollowupWayAll',
			}),
			selectTitle: function(i, v) {
				if(v == 0) { //手动新建
					//全部无剩余客户数量
					if(this.allSurplusCusCounts == 0) {
						this.$message({
							type: 'warning',
							showClose: true,
							message: '您的总创建客户数量已超标，请联系客服人员进行确认！',
							duration: 2000
						});
						return;
					}
					//今天无剩余客户数量
					if(this.daySurplusCusCounts == 0) {
						this.$message({
							type: 'warning',
							showClose: true,
							message: '您今天创建客户数量已超标，请联系客服人员进行确认！',
							duration: 2000
						});
						return;
					}
					this.titleNum = v;
					this.currentTab = 'tab' + (v + 1)
				} else if(v == 1) { //普通导入
					//全部无剩余客户数量
					if(this.allSurplusCusCounts == 0) {
						this.$message({
							type: 'warning',
							showClose: true,
							message: '您的总创建客户数量已超标，请联系客服人员进行确认！',
							duration: 2000
						});
						return;
					}
					this.titleNum = v;
					this.currentTab = 'tab' + (v + 1)
				} else if(v == 2) { //系统导入
					if(this.userinfo.login_id == 'adm'){
						//全部无剩余客户数量
						if(this.allSurplusCusCounts == 0) {
							this.$message({
								type: 'warning',
								showClose: true,
								message: '您的总创建客户数量已超标，请联系客服人员进行确认！',
								duration: 2000
							});
							return;
						}
					}
					this.titleNum = v;
					this.currentTab = 'tab' + (v + 1)
				} else { //导入记录
					this.titleNum = v;
					this.currentTab = 'tab' + (v + 1)
				}
			},
			//触发父组件关闭弹窗
			close: function() {
				this.$emit('close', false);
			},
			lookrecod: function() {
				if(this.userinfo.login_id == 'adm') {
					this.titleNum = 3;
					this.currentTab = 'tab' + 4
				} else {
					this.titleNum = 2;
					this.currentTab = 'tab' + 3;
				}
			},
			//添加客户成功后返回
			addcusclose: function() {
				this.$emit('successclose');
			},
			submit: function() {
				if(this.titleNum == 0) { //手工创建
					this.$refs.tab.addByHand();
				} else if(this.titleNum == 1) { //普通导入
					this.$refs.tab.generImport();
				} else if(this.titleNum == 2) { //系统导入
					if(this.userinfo.login_id == 'adm') {
						this.$refs.tab.sysImport();
					} else {
						this.$emit('close', false);
					}
				} else { //导入记录
					this.$emit('close', false);
				}
			}
		},
		mounted() {
			if(this.userinfo.login_id == 'adm') {
				this.lists = [{
					text: "手工创建"
				}, {
					text: "普通导入"
				}, {
					text: "系统导入"
				}, {
					text: "导入记录"
				}]
			} else {
				this.lists = [{
					text: "手工创建"
				}, {
					text: "普通导入"
				}, {
					text: "导入记录"
				}]
			}
			//今天无剩余创建客户数量
			if(this.daySurplusCusCounts == 0) {
				//全部无剩余创建客户数量则跳转到导入记录查看
				if(this.allSurplusCusCounts == 0) {
					if(this.userinfo.login_id == 'adm') {
						this.titleNum = 3;
						this.currentTab = 'tab' + 4
					} else {
						this.titleNum = 2;
						this.currentTab = 'tab' + 3;
					}
					this.titleNum = 2;
					this.currentTab = 'tab' + 3
				} else { //总的还有，今天的没有则跳转到普通导入
					this.titleNum = 1;
					this.currentTab = 'tab' + 2
				}
			}

			function clearTransform() {
				$('.el-dialog').css({
					'transform': 'none',
				})
			};
			dialogDrag();
			clearTransform(dialogCenter());
		}
	})
</script>
<style rel="stylesheet/scss" lang="less">
	* {
		margin: 0;
		padding: 0;
	}

	.red {
		color: red;
	}

	.gray {
		color: #a09d9d;
	}

	.el-dialog {
		position: fixed!important;
	}

	#createClient>.el-dialog__wrapper>.el-dialog>.el-dialog__body {
		/*max-height:600px!important;*/
		overflow: hidden!important;
	}

	#createClient {
		.createCl {
			>.el-dialog {
				width: 850px;
			}
		}
	}

	.dialog-masks {
		/*/!*display: none;*!/*/
		width: 100%;
		height: 100%;
		z-index: 619;
		background: rgba(0, 0, 0, 0.2);
		margin: 0px;
		padding: 0px;
		position: fixed;
		top: 0;
		left: 0;
	}

	.cnt-head {
		margin-bottom: 10px;
		overflow: hidden;
		line-height: 14px;
		font-size: 14px;
		background-color: #20A0FF;
		padding: 10px 10px;
		text-align: left;
		color: #fff;
		.el-icon-close {
			float: right;
			cursor: pointer;
			font-size: 14px;
		}
	}

	.ct-con-box {
		/*      padding:5px 5px 5px 0px;*/
	}

	#createClient .crm-alert-tab {
		overflow-y: auto;
    width: 100%;
		height: 368px;
    overflow-x: hidden;
		padding-bottom: 10px;
    // border: 1px solid black;
	}

	#createClient .createTab-title {
		overflow: hidden;
		margin-bottom: 10px;
		li {
			line-height: 20px;
			cursor: pointer;
			float: left;
			padding: 6px 10px;
			box-sizing: border-box;
			border: 1px solid #bfcbd9;
			border-right: 0;
			background: #fff;
			color: #1f2d3d;
			-webkit-appearance: none;
			text-align: center;
			box-sizing: border-box;
			outline: 0;
			margin: 0;
			cursor: pointer;
			transition: all .3s cubic-bezier(.645, .045, .355, 1);
			font-size: 14px;
      margin-top: 10px;
      // border: 1px solid black;
			&:nth-child(1) {
				border-radius: 2px 0 0 2px;
			}
			&:last-child {
				border-right: 1px solid #bfcbd9;
				border-radius: 0px 2px 2px 0px;
			}
		}
		.select-active {
			color: #fff;
			border-color: #20a0ff;
			background-color: #20a0ff;
		}
		.unselect-active1 {
			&:hover {
				color: #20a0ff;
			}
		}
	}

	.crm-create-box {
		/*padding:0px 20px 10px;*/
	}

	.createTab-content {
		.cu_tbcs {
			td {
				white-space: nowrap;
				padding: 5px 0 5px 10px;
				text-align: left;
				.open-w {
					user-select: none;
					color: rgb(50, 174, 229);
					cursor: pointer;
				}
				input {
					.custx
				}
				.custx {
					height: 30px;
					line-height: 30px;
					border: 1px solid #bfcbd9;
					border-radius: 2px;
					width: 180px;
				}
			}
			.cu-empty {
				height: 5px;
				line-height: 35px;
				border-top: 1px solid #ddd;
			}
			.cu_tbg {
				text-align: right;
				padding-right: 10px;
				white-space: nowrap;
				width: 120px;
			}
		}
	}

	.dialog-button {
		text-align: right;
		margin-top: 10px;
		padding-bottom: 10px;
		font-size: 12px;
		padding-right: 6px;
		.dialog-cancel {
			border: 1px solid #999;
			height: 24px;
			line-height: 22px;
			color: #666;
			cursor: pointer;
			padding: 0 5px;
			margin: 0px;
			outline: none;
		}
	}
</style>
